<template>
  <div class="project-detail">
    <ProjectTop :register="projectAttend" :type="1" />

    <!-- v-if="headerInfo.package_type == 1 && special.length" -->
    <Special
      v-if="headerInfo.package_type == 1 && (special.length || file.length)"
      :special="special"
      :file="file"
    />

    <ProjectTab :showTabItem="showTabItem" :type="1" />
  </div>
</template>

<script>
import ProjectTop from "@/components/PublicProcess/PublicTop/PublicTop.vue";
import Special from "@/components/PublicProcess/Special.vue";
import ProjectTab from "@/components/PublicProcess/Tabline/TabIndex.vue";
import { mapMutations} from 'vuex'
export default {
  data() {
    return {
      // tab的展示项
      showTabItem: ["intro", "terms", "coc", "media", "allProgram"],

      // 头部信息
      headerInfo: {},

      // 头部信心-早鸟价格
      earlyBirldPrice: {},

      // 特邀嘉宾
      special: [],
      file: [],
    };
  },

  components: {
    ProjectTop,
    Special,
    ProjectTab,
  },

  computed: {},

  created() {
    this.getProjectDetail();
    this.checkIndex();

    // 刷新数据
    this.$bus.$on("updateProjectDetail", () => {
      this.getProjectDetail();
    });
  },

  methods: {
    ...mapMutations(["checkIndex"]),
    // 详情
    getProjectDetail() {
      this.$http
        .projectDetail({
          project_id: this.$route.query.id,
          time_zone_name: Intl.DateTimeFormat().resolvedOptions().timeZone,
        })
        .then((res) => {
          this.headerInfo = res.data;
          console.log("det", res.data);

          // 头部信息
          this.$store.commit("headerDataFunc", {
            id: res.data.id,
            img: res.data.image,
            terms: res.data.terms,
            title: res.data.title,
            avatar: res.data.avatar,
            nick_name: res.data.nick_name,
            time_zone: res.data.time_zone,
            time: res.data.time,
            organization_name: res.data.organization_name,
            is_allow_register: res.data.is_allow_register,
            is_register: res.data.is_register,
            package_is_buy: res.data.package_is_buy,
            is_tipping: res.data.is_tipping,
            is_interest_club: res.data.is_interest_club,
            interest_club_url: res.data.interest_club_url,
            language: res.data.language,
            package_type: res.data.package_type,
            ticket_sale_start: res.data.ticket_sale_start,
            ticket_sale_end: res.data.ticket_sale_end,
            time2: res.data.time2,
            tip_type: res.data.tip_type,
            user_diamond: res.data.user_diamond,
          });
          // special
          this.special = res.data.special_guest;
          this.file = res.data.file_array;

          // intro
          this.$store.commit("introDataFunc", {
            channel_id: res.data.channel_id,
            introduction: res.data.introduction,
            project_single: res.data.project_single,
          });
          this.$store.commit("ProjectSingle", res.data.project_single);
          this.$store.commit("getSponsorsList", res.data.sponsors);
          this.$store.commit("getPartnerList", res.data.partners);

          // comment总分和状态
          this.$store.commit("postCommentData", res.data.post_comment);
          this.$store.commit("commentLastScoreData", res.data.comment_info);

          // question
          this.$store.commit("getQuestionData", res.data.questionnaire);

          // 证书列表
          this.$store.commit("getCertificateData", res.data.certificate);

          // result列表
          this.$store.commit("getResultData", {
            result: res.data.result,
            past: res.data.past_result,
          });

          // 单一行程or打包行程
          this.$store.commit("programType", res.data.package_type);

          // 判断tab的展示项
          let {
            is_comment,
            questionnaire_exist,
            certificate_exist,
            result_exist,
            is_show,
          } = res.data;
          let tabList = [
            is_comment,
            questionnaire_exist,
            certificate_exist,
            result_exist,
            is_show,
          ];

          tabList.forEach((v, i) => {
            switch (i) {
              case 0: {
                if (v) {
                  this.showTabItem.push("comment");
                }
                break;
              }
              case 1: {
                if (v) {
                  this.showTabItem.push("questions");
                }
                break;
              }
              case 2: {
                if (v) {
                  this.showTabItem.push("certificate");
                }
                break;
              }
              case 3: {
                if (v) {
                  this.showTabItem.push("result");
                }
                break;
              }
              case 4: {
                if (v) {
                  this.showTabItem.push("attendees");
                }
              }
            }
          });
        });
    },

    // 参加项目-注册
    async projectAttend() {
      let res = await this.$http.projectAttend({
        project_id: this.$route.query.id,
        time_zone_name: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });
      if (res.status == 200) {
        // this.headerData.is_register = true
        if(this.headerInfo.package_type == 2){
          this.$router.push({
            path: "/AllProgram",
            query: { id: this.$route.query.id, type: this.$route.query.type },
          });
        }else{
          this.$bus.$emit("updateAttendees");
          this.$bus.$emit("updateProjectDetail");
        }
      }
    },
  },
  beforeDestroy() {
    this.$bus.$off("updateAttendees");
    this.$bus.$off("updateProjectDetail");
  },
};
</script>
<style lang="scss" scoped>
.project-detail {
  width: 970px;
  height: 100vh;
  margin: 100px auto 20px;

  .detail-top {
    .top-card {
      display: flex;

      .card-img {
        flex: 1;
        height: 270px;
        border-radius: 5px;

        img {
          width: 100%;
          height: 100%;
          display: block;
          object-fit: cover;
          overflow: hidden;
        }
      }

      .card-list {
        flex: 1;
        padding-left: 24px;

        > h3 {
          color: #333;
          font-size: 20px;
        }

        .avatar-share {
          display: flex;
          align-items: center;
          padding-bottom: 10px;

          > div.avatar {
            display: flex;
            align-items: center;
            padding-right: 20px;
            border-right: 1px solid #f1f1f1;

            > span.name {
              padding-left: 12px;
              color: #333;
            }
          }

          > div.share {
            padding-left: 20px;
            color: #50b5ff;

            > i {
              padding-right: 6px;
            }
          }
        }

        > ul {
          li {
            padding: 7.5px 0;
            line-height: 1;
            color: #333;

            > i {
              padding-right: 12px;
              color: #333;
              font-size: 16px;
            }

            > span {
              color: #666;
            }
          }
        }

        .button-group {
          margin-top: 30px;

          > .el-button {
            height: 40px;
            border-radius: 5px;
            font-size: 16px;
          }
        }
      }
    }

    .top-list {
      display: flex;
      margin-top: 30px;

      > li {
        flex: 1;
        background-color: #f5fbff;
        margin-right: 10px;
        border-radius: 5px;
        padding: 20px 30px;
        color: #50b5ff;
        font-size: 16px;
        line-height: 27px;

        > span {
          padding-left: 20px;
        }

        > i {
          font-size: 27px;
        }
      }
    }

    .early-birld {
      display: flex;
      align-items: center;
      padding: 15px 10px;
      background: #f8f8f8;

      > span {
        &:first-child {
          color: #ef5959;
          font-size: 24px;
        }

        &:nth-child(2) {
          color: #999;
          font-size: 14px;
          padding-left: 10px;
          padding-right: 20px;
        }

        &:last-child {
          color: #666;
          font-size: 15px;
        }

        > i {
          color: #ef5959;
          font-style: normal;
        }
      }
    }
  }

  .special-card {
    background: #f8f8f8;
    padding: 20px;
    border: 1px solid #f1f1f1;
    align-items: center;
    border-radius: 6px;
    display: flex;
    margin-bottom: 10px;

    .special-info {
      padding-left: 20px;

      > h3 {
        font-size: 16px;
        color: #333;
      }

      span {
        font-size: 14px;
        color: #999;
      }
    }
  }

  .el-card {
    overflow: unset !important;
  }
}
</style>